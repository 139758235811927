import React from "react"
// import { Link } from "gatsby"
import addToMailchimp from "gatsby-plugin-mailchimp";
import Layout from "../components/layout"
import SEO from "../components/seo"
import SubscribeStyles from "../styles/subscribeStyles"

class Subscribe extends React.Component {
  state = {
    email: "",
    markup: ""
  };

  handleInputChange = event => {
    const target = event.target;
    const value = target.value;

    this.setState({
      email: value
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    addToMailchimp(this.state.email) 
    .then(mailchimp => {
      console.log(mailchimp);
      mailchimp.result === "success" ? this.setState({email: '', markup: `${mailchimp.result} ${mailchimp.msg}`}) : this.setState({markup: `There was an error, please check your email and try again`})
    })
    .catch((err) => {
      console.log(err)
    })
  }
  render() {
  return (
    <Layout>
      <SEO title="Home" />
      <SubscribeStyles>
        <h1>Subscribe</h1>
        <form onSubmit={this.handleSubmit}>
          <input
            type="text"
            name="email"
            placeholder="Email"
            value={this.state.email}
            onChange={this.handleInputChange}
          />
          <button type="submit">Submit</button>
          <p className='result-msg'>{this.state.markup}</p>
        </form>
      </SubscribeStyles>
    </Layout>
  )
  }
}

export default Subscribe
