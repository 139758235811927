import styled from "styled-components"

const SubscribeStyles = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  height: 100vh;
  .result-msg{
    text-align: center;
  }
  input {
    margin: 10px;
    width: 400px;
    border: 1px gray solid;
    :focus { outline: none; }
    @media (max-width: 800px) {
      width: 300px;
    }
  }
  button {
    border-radius: 5px;
    background-color: rgb(52, 113, 224);
    color: white;
    transition: ease-in-out 0.3s;
    :focus { outline: none; }
    :hover {
      transform: scale(1.1);
    }
  }
  .result-msg {
    text-transform: uppercase;
    margin-top: 20px;
  }
`

export default SubscribeStyles
